import { useRef } from "react";
import SectionTitle from "./SectionTitle";
import { useSkillLineReveal, useSkillTextReveal } from "../hooks/gsap";

const data = [
	{ id: 1, skill: "JavaScript" },
	{ id: 2, skill: "TypeScript" },
	{ id: 3, skill: "NodeJS" },
	{ id: 4, skill: "ReactJS" },
	{ id: 5, skill: "NextJS" },
	{ id: 6, skill: "Tailwind CSS" },
	{ id: 7, skill: "Framer Motion" },
	{ id: 8, skill: "WebGL" },
	{ id: 9, skill: "Prisma" },
	{ id: 10, skill: "MongoDB" },
	{ id: 11, skill: "Firebase" },
	{ id: 12, skill: "Figma" },
	{ id: 13, skill: "SEO Expertise" },
	{ id: 14, skill: "Brand Marketing" },
];
const Skills = () => {
	const skillItemRef = useRef([]);
	const skillItem2Ref = useRef([]);
	const skillTextRef = useRef([]);
	const skillText2Ref = useRef([]);

	useSkillLineReveal(skillItemRef.current);
	useSkillLineReveal(skillItem2Ref.current);
	useSkillTextReveal(skillTextRef.current);
	useSkillTextReveal(skillText2Ref.current);
	return (
		<div id='skills' className='container mx-auto mt-16 md:mt-32 skills '>
			<SectionTitle title={"Skills"} />
			<div className='grid gap-10 mt-10 sm:grid-cols-2 skills-wrapper'>
				<ul className='flex flex-col gap-10 skills-left'>
					{data
						.filter((_, i) => i < Math.floor(data.length / 2))
						.map((skill, i) => (
							<li
								key={skill.id}
								className='overflow-hidden skill-item'
								ref={(el) => (skillItemRef.current[i] = el)}>
								<div
									className='flex items-baseline gap-10'
									ref={(el) => (skillTextRef.current[i] = el)}>
									<span className='text-2xl md:text-7xl text-white/50'>
										{String(skill.id).padStart(2, 0).padEnd(3, ".")}
									</span>
									<span className='text-base md:text-4xl'>{skill.skill}</span>
								</div>
							</li>
						))}
				</ul>
				<ul className='flex flex-col gap-10 skills-right'>
					{data
						.filter((_, i) => i >= Math.floor(data.length / 2))
						.map((skill, i) => (
							<li
								key={skill.id}
								className='overflow-hidden skill-item'
								ref={(el) => (skillItem2Ref.current[i] = el)}>
								<div
									className='flex items-baseline gap-10'
									ref={(el) => (skillText2Ref.current[i] = el)}>
									<span className='text-2xl md:text-7xl text-white/50'>
										{String(skill.id).padStart(2, 0).padEnd(3, ".")}
									</span>
									<span className='text-lg md:text-4xl'>{skill.skill}</span>
								</div>
							</li>
						))}
				</ul>
			</div>
		</div>
	);
};

export default Skills;
