import { useRef } from "react";
import { useSectionTitleReveal } from "../hooks/gsap";

const SectionTitle = ({ title }) => {
	const sectionTitleRef = useRef(null);

	useSectionTitleReveal(sectionTitleRef);
	return (
		<div className='mt-40 overflow-hidden'>
			<h2 className='text-5xl section-title md:text-9xl' ref={sectionTitleRef}>
				{title}
			</h2>
		</div>
	);
};

export default SectionTitle;
