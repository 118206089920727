import React from "react";
import Project from "./Project";
import SectionTitle from "./SectionTitle";
import ai from "../assets/images/mockup-ai.png";
import ai2 from "../assets/images/mockup-ai2.png";
import aero from "../assets/images/mockup-aero.png";
import aero2 from "../assets/images/mockup-aero2.png";

const data = [
	{
		id: 1,
		title: "Ai Photos Project",
		img1: `${ai}`,
		img2: `${ai2}`,
		description:
			"Browse through a collection of imaginative and visually stunning images generated by AI. Generate an imaginative image through AI and share it with the community.",
		tools: ["Vite.js", "MongoDB", "Tailwind css", "Open-AI API"],
		liveLink: "https://ai-photos.vercel.app/",
		frontEndLink: "https://github.com/AdorRahman/AI_Photos",
	},
	{
		id: 2,
		title: "Aerocars Project",
		img1: `${aero}`,
		img2: `${aero2}`,
		description:
			"Automotive aerodynamics is the study of the aerodynamics of road vehicles and wind tunnel tests. latest blogs for cars and aerodynamics. GSAP implements to make the app dynamic.",
		tools: ["React", "GSAP"],
		liveLink: "https://aerocars-project.vercel.app/",
		frontEndLink: "https://github.com/AdorRahman/Aerocars-Project",
		// backEndLink: "https://google.com",
	},
];
const Projects = () => {
	return (
		<div className='container mx-auto mt-16 md:mt-32 projects' id='projects'>
			<SectionTitle title={"Projects"} />
			<div className='flex flex-col gap-40 mt-16 md:mt-32 projects-wrapper'>
				{data.map((project) => {
					return <Project key={project.id} project={project} />;
				})}
			</div>
		</div>
	);
};

export default Projects;
