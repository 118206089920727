import { useRef } from "react";
import { useSocialReveal } from "../hooks/gsap";
import { FaTwitter, FaGithub, FaLinkedin } from "react-icons/fa";

const data = [
	{
		id: 1,
		title: "Twitter",
		url: "https://twitter.com/crypto_ador",
		icon: <FaTwitter />,
	},
	{
		id: 2,
		title: "GitHub",
		url: "https://github.com/AdorRahman",
		icon: <FaGithub />,
	},
	{
		id: 3,
		title: "LinkedIn",
		url: "https://www.linkedin.com/in/adorrahman",
		icon: <FaLinkedin />,
	},
];

const Socials = () => {
	const socialRef = useRef(null);

	useSocialReveal(socialRef, 2);
	return (
		<div
			className='socials flex gap-5  bottom-0 md:bottom-auto  uppercase md:text-sm w-full md:w-auto justify-evenly text-xs fixed right-0 md:right-auto md:left-10 md:justify-start items-center md:top-[65%] md:-rotate-90 origin-left text-white/30 bg-black/30 md:p-5 p-3 backdrop-blur-xl z-[500]'
			ref={socialRef}>
			{data.map((social) => (
				<a
					href={social.url}
					target='_blank'
					rel='noreferrer'
					key={social.id}
					className='flex items-center text-sm duration-500 md:text-lg hover:text-cyan-400'>
					{social.icon}
					<span className='ml-2 text-xs md:text-sm '>{social.title}</span>
				</a>
			))}
		</div>
	);
};

export default Socials;
