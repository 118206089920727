import { useRef } from "react";
import { useBioReveal } from "../hooks/gsap";

const Bio = () => {
	const bioRef = useRef(null);

	useBioReveal(bioRef, 2);
	return (
		<div className='container mx-auto mt-10 overflow-hidden text-lg'>
			<p ref={bioRef}>
				My proficiency spans a spectrum of tools and frameworks, including
				ReactJS, NextJS, Tailwind CSS, Framer Motion, WebGL, Prisma, MongoDB,
				Firebase, Figma, SEO expertise, and Brand Marketing. This breadth of
				skills allows me to tackle diverse challenges and contribute effectively
				to any project or team.
			</p>
		</div>
	);
};

export default Bio;
