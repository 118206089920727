import React from "react";
import { useRef } from "react";
import { useHoverEffect } from "../hooks/useHoverEffect";
import { useImageReveal, useHeadlineReveal } from "../hooks/gsap.js";
import myimg from "../assets/images/AdorRahman.jpeg";

const data = {
	img1: `${myimg}`,
	img2: `${myimg}`,
};
const Hero = () => {
	const heroImageRef = useRef(null);
	const heroHeadline1Ref = useRef(null);
	const heroHeadline2Ref = useRef(null);

	const headlines = [heroHeadline1Ref, heroHeadline2Ref];

	useHoverEffect(heroImageRef, data.img1, data.img2);
	useImageReveal(heroImageRef, 0.5);
	useHeadlineReveal(headlines, 1.5);

	return (
		<div className='container flex items-center justify-center mx-auto mt-0 overflow-hidden hero'>
			<div className='hero-img' ref={heroImageRef}></div>
			<div className='overflow-hidden shutter shutter-left'>
				<h1
					ref={heroHeadline1Ref}
					className='text-5xl md:text-[9rem] leading-[1]'>
					Full Stack
				</h1>
			</div>
			<div className='overflow-hidden shutter shutter-right'>
				<h1 ref={heroHeadline2Ref} className='text-5xl md:text-[9rem]'>
					Developer
				</h1>
			</div>
			<div className='circle-left'></div>
			<div className='circle-right'></div>
		</div>
	);
};

export default Hero;
