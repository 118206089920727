import { useRef } from "react";
import { useHoverEffect } from "../hooks/useHoverEffect";
import { useProjectLeftRightReveal } from "../hooks/gsap";

const Project = ({ project }) => {
	const projectLeftRef = useRef(null);
	const projectRightRef = useRef(null);

	const projectsRefs = [projectRightRef, projectLeftRef];

	useHoverEffect(projectRightRef, project.img1, project.img2);
	useProjectLeftRightReveal(projectsRefs);
	return (
		<div className='grid grid-cols-5 xl:gap-10 projetct'>
			<div
				className='flex flex-col col-span-5 gap-8 sm:gap-16 xl:col-span-3 project-left'
				ref={projectLeftRef}>
				<span className='text-7xl md:text-9xl text-white/20'>
					0{project.id}
				</span>
				<h3 className='text-2xl leading-relaxed uppercase md:text-5xl text-cyan-400'>
					{project.title}
				</h3>
				<p className='text-lg text-white/75 md:text-2xl'>
					{project.description}
				</p>
				<span className='flex flex-row flex-wrap'>
					{project.tools.map((tool, i) => {
						return (
							<span key={i} className='mr-8 text-xl md:text-3xl'>
								{tool}
							</span>
						);
					})}
				</span>
				<div className='flex flex-col sm:flex-row'>
					<a
						href={project.liveLink}
						target='_blank'
						rel='noreferrer'
						className='py-4 mt-10 mr-10 text-xl uppercase duration-500 border-2 rounded-full md:text-2xl whitespace-nowrap px-14 border-white/20 hover:bg-cyan-400/20 hover:border-cyan-400/20'>
						View Site
					</a>
					{project.frontEndLink && (
						<a
							href={project.frontEndLink}
							target='_blank'
							rel='noreferrer noopener'
							className='py-4 mt-10 mr-10 text-xl uppercase duration-500 border-2 rounded-full md:text-2xl whitespace-nowrap px-14 border-white/20 hover:bg-cyan-400/20 hover:border-cyan-400/20'>
							Front-End Link
						</a>
					)}
					{project.backEndLink && (
						<a
							href={project.backEndLink}
							target='_blank'
							rel='noreferrer noopener'
							className='w-full py-4 mt-10 mr-10 uppercase duration-500 border-2 rounded-full whitespace-nowrap px-14 border-white/20 hover:bg-cyan-400/20 hover:border-cyan-400/20'>
							Back-End Link
						</a>
					)}
				</div>
			</div>

			<div
				className='col-span-5 xl:col-span-2 project-right hero-img justify-self-center lg:justify-self-end'
				ref={projectRightRef}></div>
		</div>
	);
};

export default Project;
