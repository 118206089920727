import React, { useRef } from "react";
import SectionTitle from "./SectionTitle";
import emailjs from "@emailjs/browser";
import { useContactReveal } from "../hooks/gsap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
	const formRef = useRef(null);
	const fullnameRef = useRef(null);
	const emailRef = useRef(null);
	const messageRef = useRef(null);
	const submitRef = useRef(null);

	const contactReveal = [fullnameRef, emailRef, messageRef, submitRef];
	useContactReveal(contactReveal);
	const sendEmail = (e) => {
		e.preventDefault();

		// emailjs integration
		emailjs
			.sendForm(
				process.env.REACT_APP_SERVICE_ID,
				process.env.REACT_APP_TEMPLATE_ID,
				formRef.current,
				process.env.REACT_APP_PUBLIC_ID,
			)
			.then(
				() => {
					console.log("Message sent ");
					toast("Your message sent 🤍", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
					});
				},
				() => {
					console.log("Message not sent ");
					toast.error("Message not sent! 🚫", {
						position: "top-right",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "dark",
					});
				},
			);

		// reset
		e.target.querySelector(".fullname").value = "";
		e.target.querySelector(".email").value = "";
		e.target.querySelector(".message").value = "";
	};

	return (
		<div
			className='container mx-auto mt-16 mb-20 md:mt-32 contact'
			id='contact'>
			<SectionTitle title={"Contact"} />

			<form ref={formRef} onSubmit={sendEmail} className='mt-16'>
				<div className='grid gap-10 mt-10 sm:gap-20 lg:grid-cols-2'>
					<div className='overflow-hidden from-control'>
						<input
							className='w-full px-4 duration-500 bg-transparent border outline-none fullname py-7 border-white/20 focus:border-cyan-400'
							type='text'
							placeholder='Your name'
							name='fullname'
							ref={fullnameRef}
							required
						/>
					</div>
					<div className='overflow-hidden from-control'>
						<input
							className='w-full px-4 duration-500 bg-transparent border outline-none email py-7 border-white/20 focus:border-cyan-400'
							type='email'
							placeholder='Write your email'
							name='email'
							ref={emailRef}
							required
						/>
					</div>
				</div>
				<div className='mt-10 overflow-hidden sm:mt-10 from-control'>
					<textarea
						rows='1'
						cols='30'
						className='w-full px-4 pb-8 duration-500 bg-transparent border outline-none message h-96 py-14 border-white/20 focus:border-cyan-400'
						placeholder='Write your massage'
						name='message'
						ref={messageRef}
						required
					/>
				</div>
				<div className='flex justify-center mt-10 overflow-hidden sm:mt-10 from-control'>
					<input
						className='bg-white/10 uppercase cursor-pointer border py-6 sm:py-10 px-24 rounded-full border-white/40 focus:border-cyan-400 duration-500 w-[100%] hover:bg-cyan-400/20 hover:border-cyan-400/20'
						type='submit'
						value='send'
						ref={submitRef}
					/>
				</div>
			</form>
		</div>
	);
};

export default Contact;
