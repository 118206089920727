import { useRef } from "react";
import { HashLink } from "react-router-hash-link";
import { useFooterReveal } from "../hooks/gsap";

const Footer = () => {
  const footerRef = useRef(null);

  useFooterReveal(footerRef);
  return (
    <footer className="my-40 overflow-hidden text-center text-white/50">
      <p ref={footerRef}>
        {" "}
        &copy; {new Date().getFullYear()}
        <HashLink
          smooth
          to="#home"
          className="duration-500 link-item hover:text-white"
        >
          {" "}
          Ador Rahman
        </HashLink>
        .
      </p>
    </footer>
  );
};

export default Footer;
