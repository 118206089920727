import { useRef } from "react";
import { useProjectLeftRightReveal } from "../hooks/gsap";
import { useHoverEffect } from "../hooks/useHoverEffect";
import SectionTitle from "./SectionTitle";
import myimg from "../assets/images/AdorRahman.jpeg";

const data = {
	img1: `${myimg}`,
	img2: `${myimg}`,
};

const About = () => {
	const aboutLeftRef = useRef(null);
	const aboutRightRef = useRef(null);

	const abouts = [aboutLeftRef, aboutRightRef];

	useHoverEffect(aboutLeftRef, data.img1, data.img2);
	useProjectLeftRightReveal(abouts);
	return (
		<div
			className='container relative items-center justify-center mx-auto mt-16 md:mt-32 '
			id='about'>
			<SectionTitle title={"About"} />

			<div className='flex flex-col items-center justify-center w-full gap-10 mt-16 overflow-hidden md:justify-start md:items-start md:flex-row'>
				<div className=' hero-img' ref={aboutLeftRef} />

				<div
					className='mr-4 text-lg md:w-1/2 md:text-2xl about-right'
					ref={aboutRightRef}>
					<p className='mt-10 mr-4'>
						Hi there! My approach to development revolves around solving complex
						problems with innovative solutions while ensuring that these
						solutions align seamlessly with business requirements. I take pride
						in my ability to deliver high-quality, scalable applications that
						not only meet but exceed expectations.
					</p>
					<p className='mt-10'>
						As a professional committed to growth and excellence, I firmly
						believe that my dedication to honing my skills makes me a valuable
						asset to any team. I am not just a developer; I am a dynamic problem
						solver, an advocate for innovation, and a team player ready to bring
						your projects to new heights.
					</p>
					<p className='mt-10'>
						Feel free to reach out if you're interested in collaborating or if
						you have exciting projects on the horizon. Let's create something
						extraordinary together!
					</p>
					<a
						href='https://www.linkedin.com/in/adorrahman/'
						target='_blank'
						rel='noreferrer'
						className='inline-block py-4 mt-10 mr-10 uppercase duration-500 border-2 link-item px-14 border-white/20 hover:bg-cyan-400/20 hover:border-cyan-400/20'>
						learn more
					</a>
				</div>
			</div>
		</div>
	);
};

export default About;
