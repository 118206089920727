import React, { useRef } from "react";
import { Route, Routes } from "react-router-dom";
import CustomCursor from "./components/CustomCursor";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Socials from "./components/Socials";
import { useSmoothScroll } from "./hooks/useSmoothScoll";
import { useCustomCursor } from "./hooks/useCustomCursor";
import { ToastContainer } from "react-toastify";

const App = () => {
  const innerCursorRef = useRef(null);
  const outerCursorRef = useRef(null);

  useSmoothScroll();
  useCustomCursor(innerCursorRef, outerCursorRef);
  return (
    <div className="px-5 app">
      <div className="noise"></div>
      <CustomCursor
        innerCursorRef={innerCursorRef}
        outerCursorRef={outerCursorRef}
      />

      <Navbar />
      <Socials />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Navbar footerNav />
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default App;
